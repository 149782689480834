module.exports = [{
      plugin: require('/Users/boyur/Documents/code/urbica.co/node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":31411338,"clickmap":true,"webvisor":true,"trackHash":true},
    },{
      plugin: require('/Users/boyur/Documents/code/urbica.co/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://131fca90cb194c2e8c0150103fd5a8e1@sentry.io/1355419","environment":"production","enabled":true},
    },{
      plugin: require('/Users/boyur/Documents/code/urbica.co/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
