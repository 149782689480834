// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-i-18-n-redirect-js": () => import("/Users/boyur/Documents/code/urbica.co/src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/boyur/Documents/code/urbica.co/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-contacts-js": () => import("/Users/boyur/Documents/code/urbica.co/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("/Users/boyur/Documents/code/urbica.co/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/Users/boyur/Documents/code/urbica.co/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

